import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase'; 
import { getDatabase, ref, get } from 'firebase/database';
import DevNavbar from '../devNavbar/DevNavbar'; 
import { Link } from 'react-router-dom';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { saveFavouriteCard } from '../services/CardServices';

const ComponentSelectionPage = () => {
  const [user] = useAuthState(auth);
  const [cardList, setCardList] = useState([]);
  const [favouriteCard, setFavouriteCard] = useState(null);


  useEffect(() => {
    const fetchCardList = async () => {
      if (user) {
        const db = getDatabase();
        const cardsRef = ref(db, `users/${user.uid}/mycards`);
        const snapshot = await get(cardsRef);
        const cardsData = snapshot.val();
        if (cardsData?.favouriteCard) {
          setFavouriteCard(cardsData.favouriteCard);
          delete cardsData.favouriteCard;
        }
        if (cardsData) {
          const cards = Object.entries(cardsData).map(([key, value]) => ({
            cardName: key,
            name: value.Name,
            surname: value.Surname,
            bio: value.Bio,
            image: value.Images?.profile, // Assuming 'profile' is the image key
            nickname: value.nickname // Assuming nickname is available in the card data

          }));
          setCardList(cards);
        }
      }
    };

    fetchCardList();
  }, [user]);
  const handleSaveFavouriteCard = async (cardName) => {
    await saveFavouriteCard(user, cardName, (savedCardName) => {
      console.log(`Carta preferita "${savedCardName}" salvata con successo.`);
      setFavouriteCard(savedCardName);
    }, (error) => {
      alert(error);
    });
  };


  return (
    <div className="min-h-screen bg-gradient-to-r from-[#0a0c25b3] to-indigo-400  flex items-center justify-center">
      <DevNavbar/>
      <div className="mt-14 container mx-auto px-4 py-8">
        <div className="max-w-lg bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-4">

            <div className="grid grid-cols-1 gap-4">
              {cardList.map((card, index) => (
                  <div key={index} className="border p-4 rounded-lg flex justify-between items-center">
                    <Link to={`/mycards/${card.cardName}?`} className="flex items-center">
                      <img src={card.image} alt="Profile" className="w-20 h-20 rounded-full mx-auto mb-2" />
                      <div>
                        <h3 className="text-lg font-semibold text-gray-800">{card.name} {card.surname}</h3>
                        <p className="text-gray-600 mb-2">{card.bio}</p>
                      </div>
                    </Link>
                    <FontAwesomeIcon
                        onClick={() => handleSaveFavouriteCard(card.cardName)}
                        icon={card.cardName === favouriteCard ? faStarSolid : faStarRegular}
                    />
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentSelectionPage;




import React, { useState, useRef } from 'react';
import {FaUpload, FaTimes, FaUserPlus, FaEye, FaQuestionCircle, FaEdit, FaHouseUser} from 'react-icons/fa';
import '../stilebiglietto.css';


import { Link } from 'react-router-dom'; 


const ToolBar = ({ handleImageUpload, handleRemoveBackground, isBackgroundSet, handleAggiungiCampo, handlePreview, handleApriPopupStili }) => {
    const [showPopup, setShowPopup] = useState(false);
    const fileInputRef = useRef(null);
    const [loginSuccess, setLoginSuccess] = useState(false);


    // Funzione per gestire il movimento del mouse su ciascun bottone
    const handleMouseMove = (e) => {
        const button = e.currentTarget;
        const rect = button.getBoundingClientRect();
        const x = e.clientX - rect.left - (rect.width / 2);
        const y = e.clientY - rect.top - (rect.height / 2);

        // Aumenta il coefficiente per una traslazione più pronunciata
        const dx = x * 0.2; // Aumentato da 0.05 a 0.1
        const dy = y * 0.2; // Aumentato da 0.05 a 0.1

        // Applica la trasformazione con una transizione più rapida e fluida
        button.style.transition = 'transform 0.1s ease-out'; // Rende la transizione più veloce e fluida
        button.style.transform = `translate(${dx}px, ${dy}px)`;
    };

    const handleMouseLeave = (e) => {
        const button = e.currentTarget;
        // Applica una transizione più rapida quando il mouse lascia il bottone
        button.style.transition = 'transform 0.1s ease-out'; // Assicura una transizione fluida al ritorno
        button.style.transform = '';
    };

    const infoContent = (
        <>
            <p>Ecco alcune informazioni utili sui pulsanti:</p>
            <ul>
                <li><FaUpload /> Carica Sfondo: permette di selezionare e caricare un'immagine di sfondo.</li>
                <li><FaTimes /> Rimuovi Sfondo: rimuove l'immagine di sfondo attualmente selezionata.</li>
                <li><FaUserPlus /> Nuovo Contatto: apre un form per aggiungere un nuovo contatto.</li>
                <li><FaEye /> Preview: mostra un'anteprima del lavoro corrente.</li>
                <li><FaQuestionCircle /> Aiuto: fornisce informazioni aggiuntive sulle funzioni dei pulsanti.</li>
            </ul>
        </>
    );

   

    return (
        <nav className="fixed top-6 left-1/4 right-1/4 z-a-palla h-16 flex justify-between items-center px-4 bg-black bg-opacity-60 backdrop-blur-md shadow-lg rounded-full">
<a href="/">
  <h1 className="text-xl font-bold text-white m-2.5">LEXI</h1>
</a>
            
            <div className="flex items-center gap-2">
                {[
                    
                    
                    { icon: FaUpload, action: () => fileInputRef.current.click(), label: "Carica Sfondo" },
                    { icon: FaTimes, action: handleRemoveBackground, label: "Rimuovi Sfondo", condition: isBackgroundSet },
                    { icon: FaUserPlus, action: (event) => handleAggiungiCampo({}, event), label: "Nuovo Contatto" },
                    // { icon: FaEdit, action: (event) => handleApriPopupStili(event), label: "Modifica Stili" },
                    { icon: FaEye, action: handlePreview, label: "Preview" },
                    {
                        icon: FaQuestionCircle,
                        action: () => {},
                        label: "Aiuto",
                        special: true,
                    },
                    
                ].map((btn, index) => (
                    btn.condition !== false && (
                        <div key={index} className="group relative flex flex-col items-center">
                            {btn.special ? (
                                <Link to={btn.to} className={`p-2 rounded-xl hover:bg-purple-500 hover:scale-110 transition-all duration-300 ease-in-out`}>
                                    <btn.icon className="text-white text-2xl" />
                                    <div className="tooltip-info group-hover:scale-100">{infoContent}</div>
                                </Link>
                            ) : (
                                <button
                                    className={`p-2 rounded-xl hover:bg-purple-500 hover:scale-110 transition-all duration-300 ease-in-out`}
                                    onClick={btn.action}
                                    onMouseMove={(e) => handleMouseMove(e)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <btn.icon className="text-white text-2xl" />
                                    <span className="tooltip group-hover:scale-100">{btn.label}</span>
                                </button>
                            )}
                        </div>
                    )
                ))}
                <input type="file" ref={fileInputRef} onChange={handleImageUpload} style={{ display: 'none' }} />
            </div>
        </nav>
    );
};

export default ToolBar;
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

const TutorialContext = createContext();

export const useTutorial = () => useContext(TutorialContext);

export const TutorialProvider = ({ children }) => {
    const [user] = useAuthState(auth);
    const [tutorialSeen, setTutorialSeen] = useState(false);

    useEffect(() => {
        const checkTutorialSeen = async () => {
            if (user) {
                const db = getDatabase();
                const userRef = ref(db, `users/${user.uid}/tutorialSeen`);
                const snapshot = await get(userRef);
                if (snapshot.val()) {
                    setTutorialSeen(true)
                } else {
                    setTutorialSeen(false)
                }
                console.log(tutorialSeen + " tutorial visto?")
            }
        };

        checkTutorialSeen();
    }, [user]);

    return (
        <TutorialContext.Provider value={{ tutorialSeen, setTutorialSeen }}>
            {children}
        </TutorialContext.Provider>
    );
};
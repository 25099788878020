import React, { useState, useEffect, useRef } from 'react';
import './stilebiglietto.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faTimes} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';
import { saveAs } from 'file-saver';
import Draggable from 'react-draggable';
import ToolBar from "./editToolbar/ToolBar";
import PopupFontColorPicker from "./popupFontColorPicker/PopupFontColorPicker";
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth'; // Assuming you use Firebase Authentication
import { auth } from './firebase';


const Popup = ({ aperto, chiudiPopup, salvaModifiche, rimuoviCampo, elementoModifica, posizione }) => {
  const [valore, setValore] = useState('');
  const [social, setSocial] = useState('');
  const [nome, setNome] = useState('');
  const [tipoCampo, setTipoCampo] = useState('');
  const [user] = useAuthState(auth); // Get the current user from Firebase Authentication
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    switch (tipoCampo) {
      case "social":
        switch (social) {
          case "twitter":
            setNome('Twitter')
            setValore('https://twitter.com/example')
            break;
          case "instagram":
            setNome('Instagram')
            setValore('https://instagram.com/example')
            break;
          default:
          case "facebook":
            setNome('Facebook')
            setValore('https://facebook.com/example')
            setSocial('facebook')
            break;
        }
        break;
      case "email":
        setValore('info@example.com');
        break;
      case "telefono":
        setValore('123-456-789');
        break;
    }
  }, [tipoCampo, social]);

  useEffect(() => {
    if (aperto) {
      if (!elementoModifica.id || elementoModifica.id === "") {
        setTipoCampo("telefono");
        setValore('123-456-789');
      } else {
        setValore(elementoModifica.valore || '');
        setTipoCampo(elementoModifica.tipo || '');
        if (elementoModifica.tipo === "social") {
          setNome(elementoModifica.nome || '')
          setSocial(elementoModifica.social || '')
        }
      }
    }
  }, [elementoModifica, aperto]);

  if (!aperto) return null;
  const DraggableOrNot = isMobile ? 'div' : Draggable;

  const socialIconClass = (type) => `icon ${social === type ? 'icon-center p-2 rounded-full text-purple-500' : 'text-gray-400'}`;

  return (
      <DraggableOrNot
          defaultPosition={isMobile ? null : posizione}
          onStart={(event) => {
            if (event.target.tagName === 'INPUT' || event.target.tagName === 'BUTTON') {
              return false;
            }
          }}
      >
        <div className={`pop-up-width fixed top-0 left-0 flex items-center justify-center z-[1000] ${tipoCampo === 'social' ? 'pop-up-extended' : ''}`}>
          <div className={`bg-black bg-opacity-60 backdrop-blur-md pop-up-width p-8 pb-4 rounded-lg shadow-xl relative cursor-grab max-w-md w-full ${tipoCampo === 'social' ? 'pop-up-extended' : ''}`} onClick={e => e.stopPropagation()}>
            <div className="absolute top-0 right-0 m-2 mr-3">
              <button onClick={chiudiPopup} className="text-white hover:text-gray-300">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="container-radio-button mb-4 text-white flex justify-center gap-4">
              <label className="radio-button-label">
                <input className="input-radio-button" type="radio" name="tipoCampo" value="telefono" checked={tipoCampo === 'telefono'} onChange={(e) => setTipoCampo(e.target.value)} />
                <span className="radio-custom-radio-button"></span> Telefono
              </label>
              <label className="radio-button-label">
                <input className="input-radio-button" type="radio" name="tipoCampo" value="email" checked={tipoCampo === 'email'} onChange={(e) => setTipoCampo(e.target.value)} />
                <span className="radio-custom-radio-button"></span> Email
              </label>
              <label className="radio-button-label">
                <input className="input-radio-button" type="radio" name="tipoCampo" value="social" checked={tipoCampo === 'social'} onChange={(e) => setTipoCampo(e.target.value)} />
                <span className="radio-custom-radio-button"></span> Social
              </label>
            </div>

            {tipoCampo === 'social' && (
                <div className="flex justify-center space-x-4 mb-3">
                  <FontAwesomeIcon icon={faFacebook} className={socialIconClass('facebook') + " text-2xl popup-icon"} onClick={() => setSocial('facebook')} />
                  <FontAwesomeIcon icon={faTwitter} className={socialIconClass('twitter') + " text-2xl popup-icon"} onClick={() => setSocial('twitter')} />
                  <FontAwesomeIcon icon={faInstagramSquare} className={socialIconClass('instagram') + " text-2xl popup-icon"} onClick={() => setSocial('instagram')} />
                </div>
            )}
            {tipoCampo === 'social' && (
                <input
                    type="text"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    className="input bg-purple-500 text-white p-2 rounded border border-gray-500 w-full mb-6 mt-4"
                    placeholder="Nome"
                />
            )}
            <input
                type="text"
                value={valore}
                onChange={(e) => setValore(e.target.value)}
                className="input bg-purple-500 text-white p-2 rounded border border-gray-500 w-full mb-4"
            />
            <div className="flex space-x-2 justify-center buttons-container">
              <button
                  onClick={() => salvaModifiche(elementoModifica.id, tipoCampo, valore, tipoCampo === 'social' ? social : '', tipoCampo === 'social' ? nome : '')}
                  className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded pop-up-button"
              >
                {elementoModifica.id ? 'Modifica' : 'Aggiungi'}
              </button>
              {elementoModifica.id && (
                  <button
                      onClick={() => rimuoviCampo(elementoModifica.id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded pop-up-button"
                  >
                    Rimuovi campo
                  </button>
              )}
            </div>
          </div>
        </div>
      </DraggableOrNot>
  );
};

function App() {
  const [nomeStudio, setNomeStudio] = useState('Inserisci qui la tua professione');
  const [posizionePopup, setPosizionePopup] = useState({ x: 0, y: 0 });
  const [contatti, setContatti] = useState([
    { id: 1, tipo: 'telefono', valore: '123-456-789' },
    { id: 2, tipo: 'email', valore: 'info@example.com' },
    { id: 3, tipo: 'social', social: 'facebook', valore: 'https://facebook.com/example', nome: 'Facebook' },
    { id: 4, tipo: 'social', social: 'twitter', valore: 'https://twitter.com/example', nome: 'Twitter' },
    { id: 5, tipo: 'social', social: 'instagram', valore: 'https://instagram.com/example', nome: 'Instagram' }
  ]);
  const [immagine, setImmagine] = useState(null);
  const [errore, setErrore] = useState("");
  const [popupAperto, setPopupAperto] = useState(false);
  const [elementoModifica, setElementoModifica] = useState({});
  const [nuovoSocial, setNuovoSocial] = useState('');
  const mainContainerRef = useRef(null);
  const [mostraErrore, setMostraErrore] = useState(false);
  const navigate = useNavigate();
  // const [showPreview, setShowPreview] = useState(false);
  // const [htmlPreviewContent, setHtmlPreviewContent] = useState('');


  // SEZIONE SCELTA COLORE
  // const [stileTesto, setStileTesto] = useState({
  //   font: 'Arial',
  //   colore: '#000000',
  // });
  // const applicaStile = (font, colore) => {
  //   setStileTesto({ font, colore });
  // };
  // const [popupStiliAperto, setPopupStiliAperto] = useState(false);
  // const apriPopupStili = (event) => {
  //   event?.preventDefault();
  //   event?.stopPropagation();
  //   let nuovaPosizione = event ? { x: event.clientX - 50, y: event.clientY + 50 } : { x: 0, y: 0 };
  //   setPosizionePopup(nuovaPosizione);
  //   setPopupStiliAperto(true);
  // }
  // const chiudiPopupStili = () => setPopupStiliAperto(false);

  const apriPopup = (contatto = {}, event) => {
    event?.preventDefault();
    event?.stopPropagation();

    let nuovaPosizione;

    if (!contatto.id && event) {
      nuovaPosizione = event ? { x: event.clientX - 50, y: event.clientY + 50 } : { x: 0, y: 0 };
    } else {
      // Utilizza una posizione di default o basata sull'evento, se disponibile
      nuovaPosizione = event ? { x: event.clientX -20, y: event.clientY + 30 } : { x: 0, y: 0 };
    }

    // Imposta il contatto per la modifica, oppure valori di default per un nuovo contatto
    const contattoDaModificare = contatto.id ? contatto : { id: '', tipo: '', valore: '', social: '', nome: '' };

    setElementoModifica(contattoDaModificare);
    setPopupAperto(false);
    setPosizionePopup(nuovaPosizione);
    setTimeout(() => setPopupAperto(true), 0);

    if (contatto.tipo === 'social' && contatto.social) {
      setNuovoSocial(contatto.social);
    }
  };

  const chiudiPopup = () => {
    setPopupAperto(false);
    setElementoModifica({});
  };
  const salvaModifiche = (id, tipo, nuovoValore, nuovoSocial = '', nuovoNome) => {
    // Verifica se l'ID esiste già tra i contatti
    const esisteId = contatti.some(contatto => contatto.id === id);

    if (!esisteId) {
      // Se l'ID non esiste, crea un nuovo contatto con un ID unico
      const nuovoId = contatti.length > 0 ? Math.max(...contatti.map(c => c.id)) + 1 : 1;
      const nuovoContatto = tipo === 'social' ?
          { id: nuovoId, tipo, valore: nuovoValore, social: nuovoSocial, nome: nuovoNome.charAt(0).toUpperCase() + nuovoNome.slice(1) } :
          { id: nuovoId, tipo, valore: nuovoValore };

      setContatti([...contatti, nuovoContatto]);
    } else {
      // Se l'ID esiste, aggiorna il contatto esistente
      const contattiAggiornati = contatti.map(contatto => {
        if (contatto.id === id) {
          return tipo === 'social' ?
              { ...contatto, tipo, valore: nuovoValore, social: nuovoSocial, nome: nuovoNome.charAt(0).toUpperCase() + nuovoNome.slice(1) } :
              { ...contatto, tipo, valore: nuovoValore };
        }
        return contatto;
      });

      setContatti(contattiAggiornati);
    }

    chiudiPopup();
  };

  const rimuoviCampo = (id) => {
    const contattiAggiornati = contatti.filter((contatto) => contatto.id !== id);
    setContatti(contattiAggiornati);
    chiudiPopup();
  };


  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const supportedTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (supportedTypes.includes(fileType)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImmagine(reader.result);
          if (mainContainerRef.current) {
            mainContainerRef.current.style.backgroundImage = `url(${reader.result})`;
            mainContainerRef.current.style.backgroundSize = 'cover';
          }
        };
        reader.readAsDataURL(file);
      } else {
        setErrore('File non supportato. Si prega di caricare un file JPG, PNG o GIF.');
        setMostraErrore(true); // Mostra l'errore
        setTimeout(() => setMostraErrore(false), 2000);
      }
    }
    event.target.value = null;
  };

  const handleRemoveBackground = () => {
    setImmagine(null);
    if (mainContainerRef.current) {
      mainContainerRef.current.style.backgroundImage = '';
    }
  };
//   const downloadHtmlFile = () => {
//     const htmlContent = `
// <!DOCTYPE html>
// <html lang="it">
// <head>
// <meta charset="UTF-8">
// <meta name="viewport" content="width=device-width, initial-scale=1.0">
// <title>${nomeStudio}</title>
// <style>
//   body { font-family: Arial, sans-serif; background-image: url('${immagine}'); background-size: cover; }
//   .content { text-align: center; padding: 20px; }
// </style>
// </head>
// <body>
// <div class="content">
//   <h1>${nomeStudio}</h1>
// ${contatti.map(contatto => `<p style="transition: all 0.3s ease-in-out; cursor: pointer;" onmouseover="this.style.transform = 'scale(1.1)'; this.style.color = 'yellow';" onmouseout="this.style.transform = 'scale(1)'; this.style.color = 'initial';">${contatto.valore}</p>`).join('')}
// </div>
// <h1>DM BITCH</h1>
// </body>
// </html>
//     `;
//     const blob = new Blob([htmlContent], { type: 'text/html;charset=utf-8' });
//     saveAs(blob, `${nomeStudio.replace(/\s+/g, '_').toLowerCase()}.html`);
//   };



  const handlePreview = () => {
    const htmlContent = generateHtmlContent(nomeStudio, immagine, contatti);
    localStorage.setItem('businessCardData', JSON.stringify({ nomeStudio, immagine, contatti }));
    navigate('/preview', { state: { htmlContent } });
  };
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('businessCardData'));
    if (savedData) {
      setNomeStudio(savedData.nomeStudio);
      setImmagine(savedData.immagine);
      setContatti(savedData.contatti);
      localStorage.removeItem('businessCardData');
    }
  }, []);

  const generateHtmlContent = (nomeStudio, immagine, contatti) => {
    return `
<!DOCTYPE html>
<html lang="it">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>${nomeStudio}</title>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
<style>
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
  }
  .mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('${immagine}');
    background-size: cover;
    position: relative;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .content {
    text-align: center;
    color: #ffffff;
    position: relative;
    z-index: 1000;
  }
  h1 {
    font-size: 3em;
    margin-bottom: 15px;
    font-family: "Arial Black", sans-serif;
  }
  p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  .contact-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    font-size: 1.2rem;
    margin-top: 20px;
  }
  .contact-item {
    display: inline-block;
    padding: 10px 20px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    color: #ffffff;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  .contact-item i {
    margin-right: 8px;
  }
  .contact-item:hover {
    background-color: rgba(255, 255, 255, 0.3);
    color: #000000;
  }
  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 2em;
    }
    p {
      font-size: 1em;
    }
  }
</style>
</head>
<body>
<div class="mainContainer">
  <div class="container">
    <div class="overlay"></div>
    <div class="content">
      <h1>${nomeStudio}</h1>
      <div class="contact-info">
        ${contatti.map(contatto => {
      let contattoHTML = '';
      if (contatto.tipo === 'social') {
        // Esempio per un'icona social, sostituire con le classi appropriate
        contattoHTML = `<a href="${contatto.valore}" target="_blank" class="contact-item"><i class="fab ${getFontAwesomeClass(contatto.social)}"></i> ${contatto.nome}</a>`;
      } else if (contatto.tipo === 'email') {
        contattoHTML = `<a href="mailto:${contatto.valore}" target="_blank" class="contact-item"><i class="fas fa-envelope"></i> ${contatto.valore}</a>`;
      } else {
        contattoHTML = `<p class="contact-item"><i class="fas fa-phone"></i>${contatto.valore}</p>`;
      }
      return contattoHTML;
    }).join('')}
      </div>
    </div>
  </div>
</div>
</body>
</html>
  `;
  };

  function getFontAwesomeClass(social) {
    switch (social) {
      case 'facebook': return 'fa-facebook';
      case 'twitter': return 'fa-twitter';
      case 'instagram': return 'fa-instagram';
      default: return '';
    }
  }


  return (
      <div className='mainContainer' ref={mainContainerRef} style={{ backgroundImage: `url(${immagine})`, backgroundSize: 'cover' }}>

        <div className={`errore-upload ${mostraErrore ? '' : 'nascosto'}`}>{errore}</div>

        <ToolBar
            handleImageUpload={handleImageUpload}
            handleRemoveBackground={handleRemoveBackground}
            isBackgroundSet={!!immagine}
            handleAggiungiCampo={(contatto, event) => apriPopup(contatto, event)}
            handlePreview={handlePreview}
            // handleApriPopupStili={(event) => apriPopupStili(event)}
        />
        <div className="overlay"></div>
            <div className="container">
              <div className="content" onClick={(e) => e.stopPropagation()}>
                <h1 contentEditable onBlur={(e) => setNomeStudio(e.target.innerText)}>{nomeStudio}</h1>
                <div className="contact-info" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
                  {contatti.map((contatto) => (
                      <div key={contatto.id} onClick={(e) => apriPopup(contatto, e)} className="contact-item">
                        {contatto.tipo === 'telefono' && <FontAwesomeIcon icon={faPhone} />}
                        {contatto.tipo === 'email' && <FontAwesomeIcon icon={faEnvelope} />}
                        {contatto.tipo === 'social' && contatto.social === 'facebook' && <FontAwesomeIcon icon={faFacebook} />}
                        {contatto.tipo === 'social' && contatto.social === 'twitter' && <FontAwesomeIcon icon={faTwitter} />}
                        {contatto.tipo === 'social' && contatto.social === 'instagram' && <FontAwesomeIcon icon={faInstagramSquare} />}
                        <span style={{ marginLeft: '5px' }}>{contatto.tipo === 'social' ? contatto.nome : contatto.valore}</span>
                      </div>
                  ))}
                </div>
              </div>
            </div>
        <Popup
            aperto={popupAperto}
            chiudiPopup={chiudiPopup}
            salvaModifiche={salvaModifiche}
            rimuoviCampo={rimuoviCampo}
            elementoModifica={elementoModifica}
            posizione={posizionePopup}
        />
        {/*<PopupFontColorPicker*/}
        {/*    aperto={popupStiliAperto}*/}
        {/*    chiudiPopup={chiudiPopupStili}*/}
        {/*    applicaStile={(font, colore) => {*/}
        {/*    setStileTesto({ font, colore });*/}
        {/*    }}*/}
        {/*    posizione={posizionePopup}*/}
        {/*/>*/}
      </div>
  );
}

export default App;
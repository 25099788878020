import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import DevNavbar from "../devNavbar/DevNavbar";

import "./selectmodel.css";

// Componente per le card con effetto glassmorphism
const GlassCard = ({ title, imageUrl, layout }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.removeItem('businessCardData');
      navigate(`/cardCreator/${layout.toLowerCase()}`);
  };

  return (

    
    <div className="mt-14 bg-opacity-60 backdrop-blur-md shadow-lg rounded-lg overflow-hidden m-4 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl ">
      <div className="glassmorphism-overlay absolute inset-0"></div>
      <img
        className="w-full h-48 object-cover object-center bg-white bg-opacity-100"
        src={imageUrl}
        alt={title}
      />
      <div className="p-4 relative flex">
        <button
          className="px-1 py-1 bg-indigo-500 text-white rounded hover:bg-indigo-600 transition duration-300"
          onClick={handleClick}
        >
          Scegli questo modello
        </button>
      </div>
    </div>

  );
};

// Componente per il contenitore delle card
const GlassmorphismCards = () => {
  const cards = [
    { title: "Model 1", imageUrl: "/img/mockup.png", link: "/dynamiccardA", layout:"layout1" },
    { title: "Model 2", imageUrl: "/img/mockup.png", link: "/dynamiccardB", layout:"layout2"},
    { title: "Model 3", imageUrl: "/img/mockup.png", link: "/dynamiccardC" },
    { title: "Model 4", imageUrl: "/img/mockup.png", link: "/dynamiccardD" },
    { title: "Model 5", imageUrl: "/img/mockup.png", link: "/dynamiccardE" },
    { title: "Model 6", imageUrl: "/img/mockup.png", link: "/dynamiccardF" },
    { title: "Model 7", imageUrl: "/img/mockup.png", link: "/dynamiccardG" },
    { title: "Model 8", imageUrl: "/img/mockup.png", link: "/dynamiccardH" },
    { title: "Model 9", imageUrl: "/img/mockup.png", link: "/dynamiccardI" },
    { title: "Model 10", imageUrl: "/img/mockup.png", link: "/dynamiccardJ" },
  ];

  return (
    <motion.div
      className="flex flex-wrap justify-center p-8 overflow-hidden relative"
      style={{
        backgroundImage: 'linear-gradient(to right, #0a0c25b3, #3b82f680)',
        animation: 'gradientAnimation 1s linear infinite',
      }}
    >
    <DevNavbar/>
      {/* Mostra una card per riga su dispositivi con schermo più piccolo */}
      {cards.map((card, index) => (
        <div key={index} className="w-full md:w-1/2 lg:w-1/5 mb-4">
          <GlassCard {...card} />
        </div>
      ))}
    </motion.div>
  );
};

export default GlassmorphismCards;

import { getDatabase, ref, set } from 'firebase/database';


export const saveFavouriteCard = async (user, cardName, onSuccess, onError) => {
    if (!user) {
        console.error('saveFavouriteCard: nessun utente fornito.');
        onError && onError('Nessun utente fornito');
        return;
    }

    const db = getDatabase();
    try {
        await set(ref(db, `users/${user.uid}/mycards/favouriteCard`), cardName);
        console.log(`Carta preferita "${cardName}" salvata con successo per l'utente ${user.uid}.`);
        onSuccess && onSuccess(cardName);
    } catch (error) {
        console.error('Errore durante il salvataggio della carta preferita:', error);
        onError && onError('Si è verificato un errore durante il salvataggio della carta preferita.');
    }
};

export const saveComponent = async (user, cardName, updatedFormData, selectedLayout, onSuccess, onError) => {
    if (!user || !updatedFormData) {
        console.error('saveComponent: utente o dati form non forniti.');
        onError && onError('Utente o dati form non forniti');
        return;
    }

    const db = getDatabase();
    try {
        const layoutToSave = selectedLayout || updatedFormData.layout || 'layout1'; // Gestione del layout
        const dataToSave = { ...updatedFormData, layout: layoutToSave };

        await set(ref(db, `users/${user.uid}/mycards/${cardName}`), dataToSave);
        console.log(`Componente "${cardName}" salvato con successo per l'utente ${user.uid}.`);
        onSuccess && onSuccess();
    } catch (error) {
        console.error('Errore durante il salvataggio del componente:', error);
        onError && onError('Si è verificato un errore durante il salvataggio del componente.');
    }
};

import React, { useState, useRef } from 'react';
import { FaSignOutAlt, FaEdit, FaUserCog } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { auth, signOut } from '../firebase';
import { useNavigate } from 'react-router-dom';


const ToolBar = () => {
  const [loginSuccess, setLoginSuccess] = useState(false);
  const navigate = useNavigate(); 

  

  // Funzione per gestire il movimento del mouse su ciascun bottone
  const handleMouseMove = (e) => {
    const button = e.currentTarget;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left - rect.width / 2;
    const y = e.clientY - rect.top - rect.height / 2;

    // Aumenta il coefficiente per una traslazione più pronunciata
    const dx = x * 0.2; // Aumentato da 0.05 a 0.1
    const dy = y * 0.2; // Aumentato da 0.05 a 0.1

    // Applica la trasformazione con una transizione più rapida e fluida
    button.style.transition = 'transform 0.1s ease-out'; // Rende la transizione più veloce e fluida
    button.style.transform = `translate(${dx}px, ${dy}px)`;
  };

  const handleMouseLeave = (e) => {
    const button = e.currentTarget;
    // Applica una transizione più rapida quando il mouse lascia il bottone
    button.style.transition = 'transform 0.1s ease-out'; // Assicura una transizione fluida al ritorno
    button.style.transform = '';
  };

  const handleLogout = () => {
  
    signOut(auth)
      .then(() => {
        console.log('Logout successful!');
        setLoginSuccess(false);
        navigate('/login'); // Effettuiamo il reindirizzamento a /login dopo il logout
      })
      .catch((error) => {
        console.error('Error during logout:', error);
      });
  };

  return (
    <nav className="fixed top-6 left-1/4 right-1/4 z-a-palla h-16 flex justify-between items-center px-4 bg-[#0a0c25a0] bg-opacity-60 backdrop-blur-md shadow-lg rounded-full">
      <a href="/">
        <h1 className="text-xl font-bold text-white m-2.5">LEXI</h1>
      </a>
      <div className="flex items-center gap-2">
        {[
         
          { icon: FaEdit, to: '/selectmodel' },
          { icon: FaUserCog, to: '/settings' },

          { icon: FaSignOutAlt, onClick: handleLogout },
        ].map(
          (btn, index) =>
            btn.condition !== false && (
              <div
                key={index}
                className="group relative flex flex-col items-center"
              >
                {btn.to ? (
                  <Link to={btn.to}>
                    <button
                      className={`p-2 rounded-xl hover:bg-purple-500 hover:scale-110 transition-all duration-300 ease-in-out`}
                      onMouseMove={(e) => handleMouseMove(e)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <btn.icon className="text-white text-2xl" />
                    </button>
                  </Link>
                ) : (
                  <button
                    className={`p-2 rounded-xl hover:bg-purple-500 hover:scale-110 transition-all duration-300 ease-in-out`}
                    onClick={btn.onClick}
                    onMouseMove={(e) => handleMouseMove(e)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <btn.icon className="text-white text-2xl" />
                  </button>
                )}
              </div>
            )
        )}
      </div>
    </nav>
  );
};

export default ToolBar;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  faLinkedin,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import Modal from 'react-modal';
import ProfileSettings from './editMode/EditMode';
import DevNavbar from '../devNavbar/DevNavbar';
import SocialLink from './cardsComponent/SocialLink';
import SaveModal from "./cardsComponent/SaveModal";
import useModal from '../personalHook/useModal';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

const BusinessCardAlternate1 = ({ userData, existCardName, layout }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { isVisible, openModal, closeModal } = useModal();
  const [selectedLayout, setSelectedLayout] = useState(layout || 'layout1');
  const [cardName, setCardName] = useState(existCardName ? existCardName : '');
  const [user] = useAuthState(auth);


  function openEditModal() {
    setIsOpen(true);
  }

  function closeEditModal() {
    setIsOpen(false);
  }

  return (
    <div className="min-h-screen bg-gradient-to-r from-[#0a0c25b3] to-indigo-400  flex items-center justify-center">
      <DevNavbar existingUserData={userData} selectedLayout={selectedLayout} />
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-lg bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-6 py-4">
            <div className="text-center">
              {/* <img
                src={userData.Images?.profile || ''}
                alt="Profile"
                className="mx-auto mb-4 rounded-full w-32 h-auto"
              /> */}

              <h2 className="text-xl font-bold text-gray-800">
                {userData.Name} {userData.Surname}
              </h2>
              <p className="text-gray-600">{userData.Title}</p>
              <p className="text-gray-700 mt-4">{userData.Bio}</p>
            </div>
            <div className="mt-6">
              <div className="flex items-center mb-2">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="mr-2 text-gray-600"
                />
                <span className="text-gray-700">{userData.Phone}</span>
              </div>
              <div className="flex items-center mb-2">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="mr-2 text-gray-600"
                />
                <span className="text-gray-700">{userData.Mail}</span>
              </div>
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="mr-2 text-gray-600"
                />
                <span className="text-gray-700">{userData.Address}</span>
              </div>
            </div>
            {/* <div className="mt-6 flex justify-center">
              <button className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded mr-4">
                Call Now
              </button>
              <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Send Email
              </button>
            </div> */}
            {userData.Social && (
              <div className="flex justify-center items-center mt-6">
                <span className="text-gray-700 mr-2">Connect with me:</span>
                <SocialLink icon={faLinkedin} link={userData.Social?.linkedin} />
                <SocialLink icon={faTwitter} link={userData.Social?.twitter} />
                <SocialLink icon={faInstagram} link={userData.Social?.instagram} />
              </div>
            )}

            <div className="text-center mt-6">
              <a
                href={userData.Calendar}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-700"
              >
                Book a meeting
              </a>
            </div>
            <div className="text-center mt-6">
              {!existCardName && (
                <button onClick={openModal} className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Save Component
                </button>
              )}
              {isVisible && (
                <SaveModal
                  user={user}
                  userData={userData}
                  existingCardName={existCardName}
                  selectedLayout={selectedLayout}
                  onClose={closeModal}
                />
              )}
            </div>
            {/* <div className="text-center mt-6">
              <button onClick={openEditModal} className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Edit Card
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Card"
        className="fixed mt-24 inset-0 z-50 overflow-auto flex items-center justify-center"
        overlayClassName=""
      >
        <div className="rounded-lg shadow-lg">
          <ProfileSettings onClose={closeEditModal} />
        </div>
      </Modal>
    </div>
  );
};

export default BusinessCardAlternate1;
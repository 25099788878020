import React, { useState } from "react";
import ReCAPTCHA from "react-recaptcha";
import { auth, signInWithEmailAndPassword, signInWithGoogle } from "../firebase"; // Importa il provider GoogleAuthProvider
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {useTutorial} from "../ahaMoment/TutorialContext";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [showWelcomeNotification, setShowWelcomeNotification] = useState(false);
  const navigate = useNavigate();
  const { tutorialSeen, setTutorialSeen } = useTutorial();

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password, recaptchaToken)
      .then((userCredential) => {
        console.log("Login successful!");
        setLoginSuccess(true);
        setShowWelcomeNotification(true);
        setTimeout(() => {
          navigate(tutorialSeen ? "/" : "/set");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error during login:", error);
      });
  };

  const handleGoogleLogin = () => {
    signInWithGoogle()
      .then((result) => {
        console.log("Google Login successful!");
        setLoginSuccess(true);
        setShowWelcomeNotification(true);
        setTimeout(() => {
          navigate(tutorialSeen ? "/" : "/set");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error during Google login:", error);
      });
  };

  const recaptchaLoaded = () => {
    console.log("reCAPTCHA loaded");
  };

  const verifyCallback = (token) => {
    setRecaptchaToken(token);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-[#0a0c25b3] to-indigo-400  py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white bg-opacity-25 backdrop-filter backdrop-blur-md p-8 rounded-md shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-indigo-100">
            Accedi al tuo account
          </h2>
        </div>
        <form
          className="mt-8 space-y-6"
          action="#"
          method="POST"
          onKeyPress={handleKeyPress}
        >
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="block w-full p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="block w-full p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <ReCAPTCHA
            sitekey="6LdmjYMpAAAAACzSRCgQVgwCl3eibcphxFwxvGoQ"
            onChange={verifyCallback}
            onLoaded={recaptchaLoaded}
          />
          <div>
            <button
              type="button"
              onClick={handleLogin}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
            >
              Accedi
            </button>
            <button
              type="button"
              onClick={handleGoogleLogin} // Cambia il gestore per il click del pulsante Google
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mt-4"
            >
              Accedi con Google
            </button>
          </div>
          {showWelcomeNotification && (
            <div className="rounded-md bg-green-100 p-4 mt-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-green-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM9 4a1 1 0 012 0v5a1 1 0 11-2 0V4zm2 11a1 1 0 11-2 0 1 1 0 012 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-green-800">
                    Benvenuto! Accesso riuscito con successo.
                  </p>
                </div>
              </div>
            </div>
          )}
          {!loginSuccess && (
            <p className="mt-2 text-center text-sm text-gray-100">
              Non hai un account?{" "}
              <Link
                to="/register"
                className="font-medium text-indigo-100 hover:text-indigo-300"
              >
                Registrati ora!
              </Link>
            </p>
          )}
        </form>
      </div>
    </div>

  );
}

export default Login;

import React, { useState} from 'react';
import '../stilebiglietto.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Draggable from "react-draggable";

// il componente funziona, andrebbero gestiti i font, gestito l'hover che cambia, la grandezza del testo nuova.
// in App.js è presente la sezione commentata per gestire il tutto. andrebbe inserito nello stile inline il fontfamily e colore del testo.
//

function PopupFontColorPicker({ aperto, chiudiPopup, applicaStile, posizione }) {
    const [fontSelezionato, setFontSelezionato] = useState('Arial');
    const [coloreSelezionato, setColoreSelezionato] = useState('#000000');

    if (!aperto) return null;



    return (
        <Draggable defaultPosition={posizione} onStart={(event) => {
            if (event.target.tagName === 'INPUT' || event.target.tagName === 'BUTTON') {
                return false; // Disabilita il drag se l'utente inizia a trascinare da un input o un bottone
            }
        }}>
            <div className="pop-up-width fixed top-0 left-0 flex items-center justify-center z-[1000]">
                <div className="bg-black bg-opacity-60 backdrop-blur-md pop-up-width p-6 rounded-lg shadow-xl relative cursor-grab max-w-md w-full" onClick={e => e.stopPropagation()}>
                    <div className="absolute top-0 right-0 p-2">
                        <button onClick={chiudiPopup} className="text-white hover:text-gray-300">
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <div>
                        {/* Contenuto del tuo pop-up, come la selezione del font e del colore */}
                        <div>
                            <label>Font:</label>
                            <select value={fontSelezionato} onChange={(e) => setFontSelezionato(e.target.value)}>
                                <option value="Arial">Arial</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Didot LT STD">Didot</option>
                                {/* Aggiungi altri font come necessario */}
                            </select>
                        </div>
                        <div>
                            <label>Colore:</label>
                            <input type="color" value={coloreSelezionato} onChange={(e) => setColoreSelezionato(e.target.value)} />
                        </div>
                        <button
                            onClick={() => applicaStile(fontSelezionato, coloreSelezionato)}
                            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Applica
                        </button>
                    </div>
                </div>
            </div>
        </Draggable>
    );
}

export default PopupFontColorPicker;

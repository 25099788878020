// useModal.js
import { useState } from 'react';

function useModal(initialState = false) {
    const [isVisible, setIsVisible] = useState(initialState);

    function openModal() {
        setIsVisible(true);
    }

    function closeModal() {
        setIsVisible(false);
    }

    return {
        isVisible,
        openModal,
        closeModal,
    };
}

export default useModal;

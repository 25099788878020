// import React, { useState, useEffect } from 'react';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { auth } from '../firebase';
// import { getDatabase, ref, set, get, serverTimestamp } from 'firebase/database';
// import { useNavigate, useLocation } from 'react-router-dom';
// import DevNavbar from '../devNavbar/DevNavbar';
// import {
//   getStorage,
//   ref as storageRef,
//   uploadBytes,
//   getDownloadURL,
// } from 'firebase/storage'; // Aggiorna l'import per includere `getStorage`, `ref` e `uploadBytes`
// import { format } from 'date-fns';

// const ProfileSettings = () => {
//   const [user] = useAuthState(auth);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { existingUserData, selectedLayout } = location.state || {
//     userData: null,
//     selectedLayout: null,
//   };
//   const [formData, setFormData] = useState({
//     layout: selectedLayout,
//     nickname: '',
//     Name: '',
//     Surname: '',
//     Bio: '',
//     Title: '',
//     Phone: '',
//     Mail: '',
//     Social: {
//       linkedin: '',
//       twitter: '',
//       instagram: '',
//     },
//     Address: '',
//     Calendar: '',
//     ExtLinks: '',
//     Images: {
//       profile: '',
//       copertina: '',
//     },
//     Language: {
//       English: '',
//       Italian: '',
//     },
//   });

//   const [imageUrls, setImageUrls] = useState({
//     profile: '',
//     copertina: '',
//   });

//   useEffect(() => {
//     const compileData = async () => {
//       if (user) {
//         let userData;
//         if (!existingUserData) {
//           const db = getDatabase();
//           const userRef = ref(db, `users/${user.uid}/UserDatas`);
//           const snapshot = await get(userRef);
//           userData = snapshot.val();
//         } else {
//           userData = existingUserData;
//         }
//         setFormData(userData);
//         setImageUrls(userData.Images || {});
//       }
//     };

//     compileData();
//   }, [user]);

//   const handleImageUpload = async (file, type) => {
//     try {
//       const storage = getStorage(); // Ottieni il riferimento allo storage
//       const storageFileRef = storageRef(storage, `users/${user.uid}/${type}`); // Crea il riferimento per il file
//       const snapshot = await uploadBytes(storageFileRef, file); // Carica il file nel cloud storage
//       const downloadURL = await getDownloadURL(snapshot.ref); // Ottieni l'URL per il file appena caricato

//       setImageUrls((prevUrls) => ({
//         ...prevUrls,
//         [type]: downloadURL,
//       }));

//       if (user) {
//         const db = getDatabase();
//         const userDataRef = ref(db, `users/${user.uid}/UserDatas/Images/${type}`);
//         await set(userDataRef, downloadURL);
//       }
//     } catch (error) {
//       console.error("Errore durante il caricamento dell'immagine:", error);
//     }
//   };

//   // Modificata per includere la logica "saveAsDefault"
//   // Dentro la funzione saveData
//   // Modificata per includere la logica "saveAsDefault"
//   // Dentro la funzione saveData
//   const saveData = async (saveAsDefault = false) => {
//     if (user) {
//       const db = getDatabase();
//       const updatedFormData = {
//         ...formData,
//         Images: imageUrls,
//         lastUpdate: serverTimestamp(), // Add lastUpdate timestamp using serverTimestamp()
//       };

//       // Check if timestamp is valid
//       if (!updatedFormData.timestamp || isNaN(updatedFormData.timestamp)) {
//         // If not valid, set it to the current time
//         updatedFormData.timestamp = Date.now();
//       }

//       // Formatta il timestamp in data ed ora italiana
//       const timestampFormatted = format(
//         new Date(updatedFormData.timestamp),
//         'dd/MM/yyyy HH:mm:ss'
//       );

//       if (saveAsDefault) {
//         await set(ref(db, `users/${user.uid}/UserDatas`), {
//           ...updatedFormData,
//           timestamp: timestampFormatted,
//         });
//         alert('Dati salvati con successo!');
//       }
//       navigate(`/cardCreator/${selectedLayout}`, {
//         state: { userData: updatedFormData },
//       });
//     }
//   };

//   // Nuova funzione per precompilare il form con i dati default
//   const fetchDefaultData = async () => {
//     if (user) {
//       const db = getDatabase();
//       const snapshot = await get(ref(db, `users/${user.uid}/UserDatas`));
//       const data = snapshot.val();
//       if (data) {
//         setFormData(data);
//       }
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     // Split the name by '.' to handle nested objects
//     const keys = name.split('.');
//     let updatedFormData = { ...formData };

//     // Traverse the nested structure to update the value
//     let currentLevel = updatedFormData;
//     for (let i = 0; i < keys.length; i++) {
//       const key = keys[i];
//       if (i === keys.length - 1) {
//         // Last level, update the value
//         currentLevel[key] = value;
//       } else {
//         // Traverse deeper into the nested object
//         currentLevel[key] = { ...(currentLevel[key] || {}) };
//         currentLevel = currentLevel[key];
//       }
//     }

//     setFormData(updatedFormData);
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-[#0a0c25b3] to-indigo-400 py-12 px-4 sm:px-6 lg:px-8">
//       <DevNavbar />
//       <div className="mt-12 max-w-md w-full space-y-8 bg-white bg-opacity-25 backdrop-filter backdrop-blur-md p-8 rounded-md shadow-lg">
//         <div>
//           <h2 className="mt-6 text-center text-3xl font-extrabold text-purple-100">
//             Inserisci i tuoi dati
//           </h2>
//         </div>
//         <form onSubmit={(e) => e.preventDefault()}>
//           <input
//             type="text"
//             name="Name"
//             value={formData.Name}
//             onChange={handleChange}
//             placeholder="Nome"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />
//           <input
//             type="text"
//             name="Surname"
//             value={formData.Surname}
//             onChange={handleChange}
//             placeholder="Cognome"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />
//           <textarea
//             name="Bio"
//             value={formData.Bio}
//             onChange={handleChange}
//             placeholder="Bio"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />
//           <input
//             type="email"
//             name="Mail"
//             value={formData.Mail}
//             onChange={handleChange}
//             placeholder="Mail"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />
//           <input
//             type="text"
//             name="Phone"
//             value={formData.Phone}
//             onChange={handleChange}
//             placeholder="Numero"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />
//           <input
//             type="text"
//             name="Social.linkedin"
//             value={formData.Social?.linkedin || ''} // Utilizza l'operatore di nullish coalescing per fornire un valore predefinito nel caso in cui formData.Social non sia definito
//             onChange={handleChange}
//             placeholder="Linkedin"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />
//           <input
//             type="text"
//             name="Social.twitter"
//             value={formData.Social?.twitter || ''}
//             onChange={handleChange}
//             placeholder="Twitter"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />
//           <input
//             type="text"
//             name="Social.instagram"
//             value={formData.Social?.instagram || ''}
//             onChange={handleChange}
//             placeholder="Instagram"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />

//           <input
//             type="text"
//             name="Address"
//             value={formData.Address}
//             onChange={handleChange}
//             placeholder="Indirizzo"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />

//           <input
//             type="text"
//             name="Calendar"
//             value={formData.Calendar}
//             onChange={handleChange}
//             placeholder="Calendario"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />

//           <input
//             type="text"
//             name="ExtLinks"
//             value={formData.ExtLinks}
//             onChange={handleChange}
//             placeholder="Link Esterni"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />

//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleImageUpload(e.target.files[0], 'profile')}
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => handleImageUpload(e.target.files[0], 'copertina')}
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />
//           <input
//             type="text"
//             name="Language.English"
//             value={formData.Language?.English || ''} // Utilizza l'operatore di nullish coalescing per fornire un valore predefinito nel caso in cui formData.Language non sia definito
//             onChange={handleChange}
//             placeholder="Inglese"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />

//           <input
//             type="text"
//             name="Language.Italian"
//             value={formData.Language?.Italian || ''}
//             onChange={handleChange}
//             placeholder="Italiano"
//             className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
//           />

//           <button
//             type="button"
//             onClick={() => saveData()}
//             className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-500 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
//           >
//             Submit
//           </button>
//           <button
//             type="button"
//             onClick={() => saveData(true)}
//             className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-500 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
//           >
//             Salva come Default
//           </button>
//           <button
//             type="button"
//             onClick={fetchDefaultData}
//             className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-500 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
//           >
//             Precompila con i tuoi dati
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ProfileSettings;

import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { getDatabase, ref, set, get, serverTimestamp } from 'firebase/database';
import { useNavigate, useLocation } from 'react-router-dom';
import DevNavbar from '../devNavbar/DevNavbar';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import { format } from 'date-fns';
import { useTutorial } from './TutorialContext.js';
import { saveFavouriteCard, saveComponent } from '../services/CardServices';

const ProfileSettings = ({ onClose }) => {
  const [user] = useAuthState(auth);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const [cardName, setCardName] = useState('');
  const { tutorialSeen } = useTutorial();
  const { existingUserData, selectedLayout } = location.state || {
    userData: null,
    selectedLayout: null,
  };
  const [formData, setFormData] = useState({
    layout: selectedLayout,
    nickname: '',
    Name: '',
    Surname: '',
    Bio: '',
    Title: '',
    Phone: '',
    Mail: '',
    Social: {
      linkedin: '',
      twitter: '',
      instagram: '',
    },
    Address: '',
    Calendar: '',
    ExtLinks: '',
    Images: {
      profile: '',
      copertina: '',
    },
    Language: {
      English: '',
      Italian: '',
    },
  });

  const [imageUrls, setImageUrls] = useState({
    profile: '',
    copertina: '',
  });

  useEffect(() => {
    const compileData = async () => {
      if (user) {
        let userData;
        if (!existingUserData) {
          const db = getDatabase();
          const userRef = ref(db, `users/${user.uid}/UserDatas`);
          const snapshot = await get(userRef);
          userData = snapshot.val();
        } else {
          userData = existingUserData;
        }
        setFormData(userData);
        setImageUrls(userData?.Images || {});
      }
    };

    compileData();
  }, [user]);

  const handleImageUpload = async (file, type) => {
    try {
      const storage = getStorage();
      const storageFileRef = storageRef(storage, `users/${user.uid}/${type}`);
      const snapshot = await uploadBytes(storageFileRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      setImageUrls((prevUrls) => ({
        ...prevUrls,
        [type]: downloadURL,
      }));

      if (user) {
        const db = getDatabase();
        const userDataRef = ref(
          db,
          `users/${user.uid}/UserDatas/Images/${type}`
        );
        await set(userDataRef, downloadURL);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const saveData = async (saveAsDefault = false) => {
    if (user) {
      const db = getDatabase();
      const updatedFormData = {
        ...formData,
        Images: imageUrls,
        lastUpdate: serverTimestamp(),
      };

      if (!updatedFormData.timestamp || isNaN(updatedFormData.timestamp)) {
        updatedFormData.timestamp = Date.now();
      }

      const timestampFormatted = format(
        new Date(updatedFormData.timestamp),
        'dd/MM/yyyy HH:mm:ss'
      );

      if (saveAsDefault) {
        await set(ref(db, `users/${user.uid}/UserDatas`), {
          ...updatedFormData,
          timestamp: timestampFormatted,
        });
        alert('Data saved successfully!');
      }
      await saveComponent(user, cardName, updatedFormData, selectedLayout);
      await saveFavouriteCard(user, cardName);
      navigate(`/mycards/${cardName}`, {
        state: { userData: updatedFormData },
      });
    }
    onClose();
  };
  const fetchDefaultData = async () => {
    if (user) {
      const db = getDatabase();
      const snapshot = await get(ref(db, `users/${user.uid}/UserDatas`));
      const data = snapshot.val();
      if (data) {
        setFormData(data);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');
    let updatedFormData = { ...formData };
    let currentLevel = updatedFormData;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (i === keys.length - 1) {
        currentLevel[key] = value;
      } else {
        currentLevel[key] = { ...(currentLevel[key] || {}) };
        currentLevel = currentLevel[key];
      }
    }

    setFormData(updatedFormData);
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const renderFormStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <div className="flex flex-col">
              <label htmlFor="name" className="text-white">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="Name"
                value={formData?.Name ?? ''}
                onChange={handleChange}
                placeholder="Enter your name"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="surname" className="text-white">
                Surname
              </label>
              <input
                type="text"
                id="surname"
                name="Surname"
                value={formData?.Surname ?? ''}
                onChange={handleChange}
                placeholder="Enter your surname"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <button onClick={nextStep}>Next</button>
          </div>
        );
      case 2:
        return (
          <div>
            <div className="flex flex-col">
              <label htmlFor="address" className="text-white">
                Address
              </label>
              <input
                type="text"
                id="address"
                name="Address"
                value={formData?.Address}
                onChange={handleChange}
                placeholder="Enter your address"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <button onClick={nextStep}>Next</button>
          </div>
        );
      case 3:
        return (
          <div>
            <div className="flex flex-col">
              <label htmlFor="bio" className="text-white">
                Bio
              </label>
              <textarea
                id="bio"
                name="Bio"
                value={formData?.Bio}
                onChange={handleChange}
                placeholder="Enter your biography"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <button onClick={nextStep}>Next</button>
          </div>
        );
      case 4:
        return (
          <div>
            <div className="flex flex-col">
              <label htmlFor="title" className="text-white">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="Title"
                value={formData?.Title}
                onChange={handleChange}
                placeholder="Enter your title"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <button onClick={nextStep}>Next</button>
          </div>
        );
      case 5:
        return (
          <div>
            <div className="flex flex-col">
              <label htmlFor="phone" className="text-white">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                name="Phone"
                value={formData?.Phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="mail" className="text-white">
                Email
              </label>
              <input
                type="email"
                id="mail"
                name="Mail"
                value={formData?.Mail}
                onChange={handleChange}
                placeholder="Enter your email"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <button onClick={nextStep}>Next</button>
          </div>
        );
      case 6:
        return (
          <div>
            <div className="flex flex-col">
              <label htmlFor="linkedin" className="text-white">
                Linkedin
              </label>
              <input
                type="text"
                id="linkedin"
                name="Social.linkedin"
                value={formData?.Social?.linkedin || ''}
                onChange={handleChange}
                placeholder="Enter your Linkedin URL"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="twitter" className="text-white">
                Twitter
              </label>
              <input
                type="text"
                id="twitter"
                name="Social.twitter"
                value={formData?.Social?.twitter || ''}
                onChange={handleChange}
                placeholder="Enter your Twitter URL"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="facebook" className="text-white">
                Facebook
              </label>
              <input
                type="text"
                id="facebook"
                name="Social.facebook"
                value={formData?.Social?.facebook || ''}
                onChange={handleChange}
                placeholder="Enter your Facebook URL"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <button onClick={nextStep}>Next</button>
          </div>
        );
      case 7:
        return (
          <div>
            <div className="flex flex-col">
              <label htmlFor="profile" className="text-white">
                Profile Image
              </label>
              <input
                type="file"
                id="profile"
                accept="image/*"
                onChange={(e) =>
                  handleImageUpload(e.target.files[0], 'profile')
                }
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <button onClick={nextStep}>Next</button>
          </div>
        );
      case 8:
        return (
          <div>
            <div className="flex flex-col">
              <label htmlFor="calendar" className="text-white">
                Calendar
              </label>
              <input
                type="text"
                id="calendar"
                name="Calendar"
                value={formData?.Calendar}
                onChange={handleChange}
                placeholder="Enter your calendarURL"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <button onClick={nextStep}>Next</button>
          </div>
        );
      case 9:
        return (
          <div>
            <div className="flex flex-col">
              <label htmlFor="english" className="text-white">
                English
              </label>
              <input
                type="text"
                id="english"
                name="Language.English"
                value={formData.Language?.English || ''}
                onChange={handleChange}
                placeholder="Enter English proficiency"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="italian" className="text-white">
                Italian
              </label>
              <input
                type="text"
                id="italian"
                name="Language.Italian"
                value={formData.Language?.Italian || ''}
                onChange={handleChange}
                placeholder="Enter Italian proficiency"
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
              />
            </div>
            <button onClick={nextStep}>Next</button>
          </div>
        );
      case 10:
        return (
            <div>
              <div className="flex flex-col">
                <label htmlFor="cardName" className="text-white">
                  Card Name
                </label>
                <input
                    type="text"
                    name="CardName"
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    placeholder="Enter the name of your card"
                    className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
                />
              </div>
              <div className="flex justify-between mt-4">
                <button onClick={prevStep} className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded mr-2">
                  Back
                </button>
                <button onClick={() => saveData(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Submit
                </button>
              </div>
            </div>
        );
      // Add more cases for each step
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-[#0a0c25b3] to-indigo-400 py-12 px-4 sm:px-6 lg:px-8">
  <DevNavbar />

  <div className={`max-w-md w-full space-y-8 p-8 rounded-md shadow-lg ${!tutorialSeen ? 'bg-gradient-to-r blur-sm' : ''}`}>
    <div className={` bg-opacity-25 ${!tutorialSeen ? 'filter blur-md' : ''}`}>
      <div>
        <h2 className="mt-3 mb-6 text-center text-3xl font-extrabold text-purple-100">
          Create your card
        </h2>
      </div>

      <form onSubmit={(e) => e.preventDefault()}>
        <div className="space-y-4">{renderFormStep()}</div>
      </form>
    </div>
  </div>
</div>


  );
};
  

export default ProfileSettings;

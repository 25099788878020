import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, set } from 'firebase/database';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import GeneralNavbar from '../generalNavbar/GeneralNavbar';

function Impostazioni() {
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState({
    Name: '',
    Surname: '',
    Mail: '',
    Phone: '',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const db = getDatabase();
        const userDatasRef = ref(db, `users/${user.uid}/UserDatas`);
        try {
          const snapshot = await get(userDatasRef);
          if (snapshot.exists()) {
            const data = snapshot.val();
            setUserData(data);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      const db = getDatabase();
      const userDatasRef = ref(db, `users/${user.uid}/UserDatas`);
      try {
        await set(userDatasRef, userData);
        alert('Dati salvati con successo!');
      } catch (error) {
        console.error('Error saving user data:', error);
      }
    }
  };

  return (
    <>
    <div className="min-h-screen bg-gradient-to-r from-[#0a0c25b3] to-indigo-400 flex items-center justify-center">
      <GeneralNavbar />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-14">
        <form
          onSubmit={handleSubmit}
          className="bg-[#0a0c2540] shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-white text-sm font-bold mb-2"
            >
              Nome:
            </label>
            <input
              type="text"
              id="name"
              name="Name"
              value={userData.Name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="surname"
              className="block text-white text-sm font-bold mb-2"
            >
              Cognome:
            </label>
            <input
              type="text"
              id="surname"
              name="Surname"
              value={userData.Surname}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="mail"
              className="block text-white text-sm font-bold mb-2"
            >
              Mail:
            </label>
            <input
              type="email"
              id="mail"
              name="Mail"
              value={userData.Mail}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="phone"
              className="block text-white text-sm font-bold mb-2"
            >
              Telefono:
            </label>
            <input
              type="tel"
              id="phone"
              name="Phone"
              value={userData.Phone}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex items-center justify-end md:justify-between">
            <button
              type="submit"
              className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Salva
            </button>
          </div>
        </form>
        <div className="bg-[#0a0c2540] shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h2 className="text-white text-lg font-extrabold mb-4">Contatto e Assistenza</h2>
          <div className="mb-4">
            <p className="text-white mb-2">Telefono: +39 123 456 789</p>
            <p className="text-white mb-2">Email: info@lexiweb.it</p>
            <p className="text-white font-semibold mt-5">Contattaci per ogni domanda!</p>
          </div>
        </div>
      </div>
    </div>
  </>
  
  );
}

export default Impostazioni;

import React, { useState, useEffect } from 'react';
import { FaUsers, FaEdit, FaUserCog } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { getDatabase, ref, get, set } from 'firebase/database';
import { useTutorial } from '../ahaMoment/TutorialContext.js';

const ToolBar = ({ existingUserData, selectedLayout }) => {
  const [user] = useAuthState(auth);
  const [currentStep, setCurrentStep] = useState(0);
  const { tutorialSeen, setTutorialSeen } = useTutorial();

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      updateTutorialState(); // Aggiorna lo stato del tutorial nel database dopo che è stato completato
    }
  };

  const updateTutorialState = async () => {
    try {
      const db = getDatabase();
      await set(ref(db, `users/${user.uid}/tutorialSeen`), true);
      // Refresh the page after updating the tutorial state
      setTutorialSeen(true);
    } catch (error) {
      console.error('Error updating tutorial state:', error);
    }
  };

  const steps = [
    {
      icon: FaEdit,
      to: '/selectmodel',
      tutorialText: 'Clicca qui per modificare il modello.',
    },
    {
      icon: FaUserCog,
      to: '/settings',
      state: {
        existingUserData: existingUserData,
        selectedLayout: selectedLayout,
      },
      tutorialText: 'Clicca qui per accedere alle impostazioni.',
    },
    {
      icon: FaUsers,
      to: '/mycards',
      tutorialText: 'Clicca qui per accedere alle tue card.',
    },
    
  ];

  return (
    <nav className="fixed top-6 left-1/4 right-1/4 z-a-palla h-16 flex justify-between items-center px-4 bg-[#0a0c25a0] bg-opacity-60 backdrop-blur-md shadow-lg rounded-full">
      <a href="/">
        <h1 className="text-xl font-bold text-white m-2.5">LEXI</h1>
      </a>
      <div className="flex items-center gap-2">
      {steps.map((step, index) => (
  <div key={index} className="group relative flex flex-col items-center">
    {step.to ? (
      <Link to={step.to} state={step.state}>
        <button
          className={`p-2 rounded-xl hover:bg-purple-500 hover:scale-110 transition-all duration-300 ease-in-out`}
          onClick={() => {
            setCurrentStep(index);
          }}
        >
          <step.icon className="text-white text-2xl" />
        </button>
      </Link>
    ) : (
      <button
        className={`p-2 rounded-xl hover:bg-purple-500 hover:scale-110 transition-all duration-300 ease-in-out`}
        onClick={() => {
          step.onClick(); // Call the onClick function for the step
          setCurrentStep(index);
        }}
      >
        {step.icon ? <step.icon className="text-white text-2xl" /> : 'Next'}
      </button>
    )}
    {!tutorialSeen && index === currentStep && (
      <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-white rounded-md shadow-md p-4 text-sm">
        <p>{step.tutorialText}</p>
        <button onClick={handleNext} className="mt-2 text-indigo-500 underline">
          Next
        </button>
      </div>
    )}
  </div>
))}
      </div>
    </nav>
  );
};

export default ToolBar;

import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { getDatabase, ref, get } from 'firebase/database';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase'; // Assicurati di importare correttamente il modulo auth da firebase

const QRGenerator = () => {
  const [user] = useAuthState(auth);
  const [nickname, setNickname] = useState('');

  useEffect(() => {
    const fetchNickname = async () => {
      if (user) {
        const uid = user.uid;
        const db = getDatabase();
        const userDatasRef = ref(db, `users/${uid}/UserDatas/nickname`);
        const snapshot = await get(userDatasRef);
        const nickname = snapshot.val();
        if (nickname) {
          setNickname(nickname);
        } else {
          console.log("Nickname non trovato nel percorso specificato.");
        }
      } else {
        console.log("Utente non autenticato.");
      }
    };

    fetchNickname();
  }, [user]);

  return (
    <div>
      {nickname && (
        <QRCode value={`https://app.lexiweb.it/${nickname}`} bgColor="#FFF" fgColor="#0a0c25b3" />
      )}
    </div>
  );
};

export default QRGenerator;

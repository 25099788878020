
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Importa useLocation dal react-router-dom
import { useAuthState } from 'react-firebase-hooks/auth'; 
import { analytics, auth } from '../firebase'; // Importa direttamente l'oggetto analytics

const Analytics = () => {
  const [newUsersCount, setNewUsersCount] = useState(0);
  const [user] = useAuthState(auth); // Get the current user from Firebase Authentication
  const location = useLocation(); // Ottieni la posizione corrente dal routing

  useEffect(() => {
    // Controlla se l'utente si trova sulla pagina '/mycard'
    if (location.pathname === '/creation') {
      // Recupera il numero di nuovi utenti dagli analytics di Firebase
      const fetchNewUsersCount = async () => {
        try {
          const { data } = await analytics.getAnalytics().getEventCount('open_page_event', {
            startDate: '30daysAgo',
            endDate: 'today',
            dimensions: [{ name: 'user_id', operator: 'count_unique' }],
          });
          setNewUsersCount(data);
        } catch (error) {
          console.error('Errore durante il recupero dei dati di analytics:', error);
        }
      };

      fetchNewUsersCount();
    }
  }, [location.pathname]); // Esegui l'effetto solo quando cambia la posizione

  return (
    <div className="container mx-auto py-1 md:px-6 lg:px-12">
      <section className="mb-10 text-white">
        <h5 className="mb-6 text-xl font-bold">Last 30 days</h5>
        <div className="block rounded-lg bg-[#0a0c25a0] shadow-lg">
          <div className="grid lg:grid-cols-3">
            <div className=" p-6 lg:border-r">
              <p className="mb-1 text-white font-medium">People you met</p>
              <div className="flex items-center justify-between">
                <p>
                  <span className="mr-3 text-xl font-semibold text-white">{newUsersCount}</span>
                </p>
              </div>
            </div>
            {/* Altri elementi di analytics qui */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Analytics;

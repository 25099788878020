import React, { useState } from 'react';
import { saveComponent } from '../../services/CardServices'; // Assicurati che il percorso sia corretto
import { useNavigate } from 'react-router-dom'; // Importa useNavigate da react-router-dom

const SaveModal = ({ user, userData, existingCardName, selectedLayout, onClose }) => {
    const [cardName, setCardName] = useState(existingCardName || '');
    const navigate = useNavigate();

    const handleSave = async () => {
        if (!user || !cardName.trim()) return;
        await saveComponent(user, cardName, userData, selectedLayout,
            () => {
                onClose();
                navigate(`/mycards/${cardName}`, {
                    state: { userData },
                });
            },
            (error) => {
                // Gestisci l'errore qui, se necessario
                console.error(error);
            }
        );
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" style={{ zIndex: 10 }}>
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-lg font-bold mb-4">Inserisci il nome della card</h2>
                <input
                    type="text"
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    className="border border-gray-400 p-2 rounded-lg mb-4 w-full"
                />
                <div className="flex justify-end">
                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2" onClick={handleSave}>
                        Salva
                    </button>
                    <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={onClose}>
                        Annulla
                    </button>
                </div>
            </div>
        </div>
    );
};
export default SaveModal;
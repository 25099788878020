import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialLink = ({ icon, link }) => {
    return link ? (
        <a href={link} target="_blank" rel="noopener noreferrer" className="text-purple-500 hover:text-purple-700 ml-2">
            <FontAwesomeIcon icon={icon} />
        </a>
    ) : null;
};

export default SocialLink;

import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { getDatabase, ref, set, get, serverTimestamp } from 'firebase/database';
import { useNavigate, useLocation } from 'react-router-dom';
import DevNavbar from '../../devNavbar/DevNavbar';
import {
    getStorage,
    ref as storageRef,
    uploadBytes,
    getDownloadURL,
  } from 'firebase/storage'; // Aggiorna l'import per includere `getStorage`, `ref` e `uploadBytes`l'import per includere `getStorage`, `ref` e `uploadBytes`
import { format } from 'date-fns';

const EditMode = ({ onClose }) => {
    const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { existingUserData, selectedLayout } = location.state || {
    userData: null,
    selectedLayout: null,
  };
  const [formData, setFormData] = useState({
    layout: selectedLayout,
    nickname: '',
    Name: '',
    Surname: '',
    Bio: '',
    Title: '',
    Phone: '',
    Mail: '',
    Social: {
      linkedin: '',
      twitter: '',
      instagram: '',
    },
    Address: '',
    Calendar: '',
    ExtLinks: '',
    Images: {
      profile: '',
      copertina: '',
    },
    Language: {
      English: '',
      Italian: '',
    },
  });

  const [imageUrls, setImageUrls] = useState({
    profile: '',
    copertina: '',
  });

  useEffect(() => {
    const compileData = async () => {
      if (user) {
        let userData;
        if (!existingUserData) {
          const db = getDatabase();
          const userRef = ref(db, `users/${user.uid}/UserDatas`);
          const snapshot = await get(userRef);
          userData = snapshot.val();
        } else {
          userData = existingUserData;
        }
        setFormData(userData);
        setImageUrls(userData.Images || {});
      }
    };

    compileData();
  }, [user]);

  const handleImageUpload = async (file, type) => {
    try {
      const storage = getStorage(); // Ottieni il riferimento allo storage
      const storageFileRef = storageRef(storage, `users/${user.uid}/${type}`); // Crea il riferimento per il file
      const snapshot = await uploadBytes(storageFileRef, file); // Carica il file nel cloud storage
      const downloadURL = await getDownloadURL(snapshot.ref); // Ottieni l'URL per il file appena caricato

      setImageUrls((prevUrls) => ({
        ...prevUrls,
        [type]: downloadURL,
      }));

      if (user) {
        const db = getDatabase();
        const userDataRef = ref(db, `users/${user.uid}/UserDatas/Images/${type}`);
        await set(userDataRef, downloadURL);
      }
    } catch (error) {
      console.error("Errore durante il caricamento dell'immagine:", error);
    }
  };

  // Modificata per includere la logica "saveAsDefault"
  // Dentro la funzione saveData
  // Modificata per includere la logica "saveAsDefault"
  // Dentro la funzione saveData
  const saveData = async (saveAsDefault = false) => {
    if (user) {
      const db = getDatabase();
      const updatedFormData = {
        ...formData,
        Images: imageUrls,
        lastUpdate: serverTimestamp(),
      };
  
      // Check if timestamp is valid
      if (!updatedFormData.timestamp || isNaN(updatedFormData.timestamp)) {
        // If not valid, set it to the current time
        updatedFormData.timestamp = Date.now();
      }
  
      // Formatta il timestamp in data ed ora italiana
      const timestampFormatted = format(
        new Date(updatedFormData.timestamp),
        'dd/MM/yyyy HH:mm:ss'
      );
  
      if (saveAsDefault) {
        // Salva i dati come default
        await set(ref(db, `users/${user.uid}/mycards/cardname`), {
          ...updatedFormData,
          timestamp: timestampFormatted,
        });
        alert('Dati salvati con successo come default!');
      } else {
        // Salva i dati della carta corrente
        await set(ref(db, `users/${user.uid}/mycards/${formData.nickname}`), {
          ...updatedFormData,
          timestamp: timestampFormatted,
        });
        alert('Dati della carta salvati con successo!');
      }
  
      navigate(`/cardCreator?layout=${selectedLayout}`, {
        state: { userData: updatedFormData },
      });
    }
    onClose();
  };
  

  // Nuova funzione per precompilare il form con i dati default
  const fetchDefaultData = async () => {
    if (user) {
      const db = getDatabase();
      const snapshot = await get(ref(db, `users/${user.uid}/mycards/cardname`));
      const data = snapshot.val();
      if (data) {
        setFormData(data);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Split the name by '.' to handle nested objects
    const keys = name.split('.');
    let updatedFormData = { ...formData };

    // Traverse the nested structure to update the value
    let currentLevel = updatedFormData;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (i === keys.length - 1) {
        // Last level, update the value
        currentLevel[key] = value;
      } else {
        // Traverse deeper into the nested object
        currentLevel[key] = { ...(currentLevel[key] || {}) };
        currentLevel = currentLevel[key];
      }
    }

    setFormData(updatedFormData);
  };

  return (
    <div className="min-h-screen flex items-center justify-center mt-[500px] py-12 px-4 sm:px-6 lg:px-8">
      <DevNavbar />
      <div className="mt-12 max-w-md w-full space-y-8 bg-white bg-opacity-25 backdrop-filter backdrop-blur-md p-8 rounded-md shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-purple-800">
            Inserisci i tuoi dati
          </h2>
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            placeholder="Nome"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <input
            type="text"
            name="Surname"
            value={formData.Surname}
            onChange={handleChange}
            placeholder="Cognome"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <textarea
            name="Bio"
            value={formData.Bio}
            onChange={handleChange}
            placeholder="Bio"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <input
            type="email"
            name="Mail"
            value={formData.Mail}
            onChange={handleChange}
            placeholder="Mail"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <input
            type="text"
            name="Phone"
            value={formData.Phone}
            onChange={handleChange}
            placeholder="Numero"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <input
            type="text"
            name="Social.linkedin"
            value={formData.Social?.linkedin || ''} // Utilizza l'operatore di nullish coalescing per fornire un valore predefinito nel caso in cui formData.Social non sia definito
            onChange={handleChange}
            placeholder="Linkedin"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <input
            type="text"
            name="Social.twitter"
            value={formData.Social?.twitter || ''}
            onChange={handleChange}
            placeholder="Twitter"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <input
            type="text"
            name="Social.instagram"
            value={formData.Social?.instagram || ''}
            onChange={handleChange}
            placeholder="Instagram"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />

          <input
            type="text"
            name="Address"
            value={formData.Address}
            onChange={handleChange}
            placeholder="Indirizzo"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />

          <input
            type="text"
            name="Calendar"
            value={formData.Calendar}
            onChange={handleChange}
            placeholder="Calendario"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />

          <input
            type="text"
            name="ExtLinks"
            value={formData.ExtLinks}
            onChange={handleChange}
            placeholder="Link Esterni"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />

          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e.target.files[0], 'profile')}
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e.target.files[0], 'copertina')}
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <input
            type="text"
            name="Language.English"
            value={formData.Language?.English || ''} // Utilizza l'operatore di nullish coalescing per fornire un valore predefinito nel caso in cui formData.Language non sia definito
            onChange={handleChange}
            placeholder="Inglese"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />

          <input
            type="text"
            name="Language.Italian"
            value={formData.Language?.Italian || ''}
            onChange={handleChange}
            placeholder="Italiano"
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />

          {/* <button
            type="button"
            onClick={() => saveData()}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-500 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
          >
            Submit
          </button> */}
          <button
            type="button"
            onClick={() => saveData(true)}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-500 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
          >
            Salva card
          </button>
          <button
            type="button"
            onClick={fetchDefaultData}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-500 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
          >
            Precompila con i tuoi dati
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditMode;
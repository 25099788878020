import React, { useState, useEffect } from "react";
import { auth, createUserWithEmailAndPassword } from "../firebase";
import { Link } from "react-router-dom";
import { getDatabase, ref, set, child, get } from 'firebase/database';

function Register() {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nickname, setNickname] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const checkNicknameAvailability = async (nickname) => {
    const db = getDatabase();
    const usersRef = ref(db, '/');
    const snapshot = await get(child(usersRef, '/'));
    if (snapshot.exists()) {
      const users = snapshot.val();
      const nicknames = Object.values(users).map(user => user.userDatas?.personalData?.nickname); // Aggiungo '?' per evitare l'errore di accesso a proprietà di undefined
      return !nicknames.includes(nickname);
    }
    return true;
  };

  const handleRegister = async (e) => {
    e.preventDefault(); // Prevent form submission
    
    if (email !== confirmEmail) {
      setError("Email and confirm email do not match");
      return;
    }

    const isNicknameAvailable = await checkNicknameAvailability(nickname);
    if (!isNicknameAvailable) {
      setError("Nickname already taken. Please choose another one.");
      return;
    }

    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Registration successful
      console.log("Registration successful!");
      
      // Salvataggio del nickname nel database Firebase
      const db = getDatabase();
      const userRef = ref(db, `users/${userCredential.user.uid}/UserDatas/`);
      await set(userRef, {
        nickname: nickname,
      });
      
      setSuccess(true);
    } catch (error) {
      // Error handling
      console.error("Error during registration:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleRegister(e);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-[#0a0c25b3] to-indigo-400 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white bg-opacity-25 backdrop-filter backdrop-blur-md p-8 rounded-md shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Registra un nuovo account
          </h2>
        </div>
        <form
          className="mt-8 space-y-6"
          action="#"
          method="POST"
          onKeyPress={handleKeyPress}
        >
        <input
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            placeholder="Nickname"
            className="block w-full p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="block w-full p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <input
            type="email"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            placeholder="Conferma Email"
            className="block w-full p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="block w-full p-1 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          
          <div>
            <button
              type="submit"
              onClick={handleRegister}
              disabled={loading || !email || !confirmEmail || !password || !nickname}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4"
            >
              Registrati
            </button>
          </div>
          <p className="mt-2 text-center text-sm text-gray-100">
            Hai già un account?{" "}
            <Link
              to="/login"
              className="font-medium text-indigo-100 hover:text-indigo-300"
            >
              Accedi
            </Link>
          </p>
          {loading && <p>Loading...</p>}
          {error && <p>Error: {error}</p>}
          {success && <p>Registrato con successo! Controlla la tua email per confermare la registrazione.</p>}
        </form>
      </div>
    </div>
  );
}

export default Register;

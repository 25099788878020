import React, { useState, useEffect, createContext, useContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Login from "./auth/Login";
import Register from "./auth/Register";
import SelectModel from "./selectmodel/SelectModel";  
import UserPage from "./userPage/UserPage";
import MyCards from "./myCards/MyCards";
import AhaMoment from './ahaMoment/AhaMoment';
import Success from "./success/Success";
import CardComponentSelector from "./myCards/CardComponentSelector";
import PublicSelect from "./publicFolder/PublicSelect";
import Settings from "./settings/Settings";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import { auth } from './firebase';
import { TutorialProvider } from './ahaMoment/TutorialContext';

// Creazione di un contesto per lo stato di autenticazione
const AuthContext = createContext();

const AppWrapper = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        // Controlla se c'è uno stato di autenticazione salvato nel localStorage
        return JSON.parse(localStorage.getItem('isLoggedIn')) || false;
    });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setIsLoggedIn(!!user);
        });
    
        return unsubscribe;
    }, []); // Rimuovi isLoggedIn come dipendenza per evitare loop infinito

    // Salva lo stato di autenticazione nel localStorage
    useEffect(() => {
        localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
    }, [isLoggedIn]);

    return (
        <AuthContext.Provider value={{ isLoggedIn }}>
            <TutorialProvider>
                <Router>
                    <Routes>

                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/success" element={<Success />} />

                        <Route path="/" element={<ProtectedRoute component={UserPage} />} />
                        <Route path='/mycards' element={<ProtectedRoute component={MyCards} />} />
                        <Route path='/set' element={<AhaMoment/> } />
                        <Route path='/settings' element={<Settings/> } />




                        <Route path='/cardCreator/:layout' element={<CardComponentSelector/> } />
                        <Route path='/mycards/:cardName' element={<CardComponentSelector/> } />
                        <Route path='/:nickname' element={<CardComponentSelector/> } />

                        {/*<Route path='/publicCards/:nickname/:cardName' element={<CardComponentSelector/> } />*/}

                        <Route path='/publicselect' element={<PublicSelect/> } />





                        <Route path="/selectmodel" element={<SelectModel />} />
                        <Route path="/creator" element={<ProtectedRoute component={App} />} />
                        
                    </Routes>
                </Router>
            </TutorialProvider>
        </AuthContext.Provider>
    );
};


// Componente di navigazione protetto
const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = useContext(AuthContext);

    if (!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    return <Component {...rest} />;
};

ReactDOM.render(
    <AppWrapper />,
    document.getElementById('root')
);

import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth'; // Assuming you use Firebase Authentication
import GeneralNavbar from '../generalNavbar/GeneralNavbar';
import Stats from '../stats/Stats';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { getDatabase, ref, get, set } from 'firebase/database';
import PublicSelect from '../publicFolder/PublicSelect';
import { useNavigate } from 'react-router-dom';

import { FaAddressCard, FaIdCard, FaUsers } from 'react-icons/fa';

function UserPage() {
  const [user] = useAuthState(auth);
  const [favoriteCard, setFavoriteCard] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchFavoriteCard = async () => {
      if (user) {
        const db = getDatabase();
        const favoriteCardRef = ref(
          db,
          `users/${user.uid}/mycards/favouriteCard`
        );
        const snapshot = await get(favoriteCardRef);
        const favoriteCardName = snapshot.val();

        if (favoriteCardName) {
          // Fetch the details of the favorite card
          const cardRef = ref(
            db,
            `users/${user.uid}/mycards/${favoriteCardName}`
          );
          const cardSnapshot = await get(cardRef);
          const cardData = cardSnapshot.val();

          if (cardData) {
            setFavoriteCard({
              cardName: favoriteCardName,
              name: cardData.Name,
              surname: cardData.Surname,
              bio: cardData.Bio,
              image: cardData.Images?.profile,
            });
          }
        }
      }
    };

    fetchFavoriteCard();
  }, [user]);

  return (
    <>
      <div className="flex flex-col">
        <GeneralNavbar />
        <div className="flex-grow flex items-center justify-center bg-gradient-to-r from-[#0a0c25b3] to-indigo-400 px-4 sm:px-6 lg:px-8">
          <div className="flex-col mt-28 mb-10">
            <div className="p-5 mb-10 bg-[#0a0c25a0] bg-opacity-60 backdrop-blur-md shadow-lg rounded-md w-full min-h-full z-10">
              <h1 className="text-white">Meet new people!</h1>
              {/* <Card /> */}
              {favoriteCard && (
                <div className="max-w-lg bg-white rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-4">
                    <Link
                      to={`/mycards/${favoriteCard.cardName}`}
                      className=" rounded-lg"
                    >
                      <img
                        src={favoriteCard.image}
                        alt="Profile"
                        className="w-20 h-20 rounded-full mx-auto mb-2"
                      />
                      <div>
                        <h3 className="text-lg font-semibold text-gray-800">
                          {favoriteCard.name} {favoriteCard.surname}
                        </h3>
                        <p className="text-gray-600 mb-2">{favoriteCard.bio}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center justify-center p-5 mb-10 bg-[#0a0c25a0] bg-opacity-60 backdrop-blur-md shadow-lg rounded-md w-full min-h-full z-10">
              <div className="flex flex-col items-center justify-center w-full h-full">
                <PublicSelect className="w-full" />
              </div>
            </div>

            <div className="p-5 mt-10 flex bg-[#0a0c25a0] bg-opacity-60 backdrop-blur-md shadow-lg rounded-md w-full min-h-full z-10">
              <Link
                to="/mycards"
                className="flex items-center space-x-2 text-indigo-100 hover:text-indigo-300"
              >
                <FaAddressCard />
                <span>Esplora le tue card</span>
              </Link>
            </div>
            <div className="p-5 mt-10 bg-[#0a0c25a0] bg-opacity-60 backdrop-blur-md shadow-lg rounded-md w-full min-h-full z-10">
              <button className="flex items-center space-x-2 text-indigo-100 hover:text-indigo-300">
                <FaIdCard />
                <span>Esplora le tue firme elettroniche</span>
              </button>
            </div>
            <div className="p-5 mt-10 mb-10 bg-[#0a0c25a0] bg-opacity-60 backdrop-blur-md shadow-lg rounded-md w-full min-h-full z-10">
              <button className="flex items-center space-x-2 text-indigo-100 hover:text-indigo-300">
                <FaUsers />
                <span>Il tuo team</span>
              </button>
            </div>
            <Stats />
          </div>
        </div>
      </div>
    </>
  );
}

export default UserPage;

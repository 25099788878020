import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth"; // Importa signInWithEmailAndPassword, signOut
import { getAnalytics } from "firebase/analytics"; 
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth"; // Importa GoogleAuthProvider e signInWithPopup
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage"; // Aggiungi questa importazione per storage



const firebaseConfig = {
  apiKey: "AIzaSyCIbpqS0d_krlKzxHw2e9s0I4DaSPhn33E",
  authDomain: "weblexi.firebaseapp.com",
  projectId: "weblexi",
  storageBucket: "weblexi.appspot.com",
  messagingSenderId: "776091352801",
  appId: "1:776091352801:web:57d6f8d3fe30511f8cc1a0",
  measurementId: "G-MW0FP9NJTF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app); // Inizializza Firebase Storage
const auth = getAuth(app); // Use getAuth instead of createUserWithEmailAndPassword
const database = getDatabase(app);
const storage = getStorage(app); // Inizializza Firebase Storage


const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider); // Assicurati che signInWithPopup sia importato correttamente
};


export { app, functions, auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, analytics, signInWithGoogle, signInWithPopup, database, storage };

import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import DynamicCardA from "../dynamicCard/DynamicCardA";
import DynamicCardB from "../dynamicCard/DynamicCardB";
import {get, getDatabase, ref} from "firebase/database";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../firebase";

const CardComponentSelector = () => {
    const [user] = useAuthState(auth);
    const location = useLocation();
    const navigate = useNavigate();
    const { cardName, nickname, layout } = useParams();
    const [userData, setUserData] = useState(location.state?.userData || null);
    const [selectedLayout, setSelectedLayout] = useState(layout || null);

    useEffect(() => {
        const fetchUserData = async () => {
            let finalLayout = layout;
            if (nickname) {
                const db = getDatabase();
                const nicknamesRef = ref(db, `nicknames/${nickname}`);
                const snapshot = await get(nicknamesRef);
                const userUid = snapshot.val();
                if (userUid) {
                    console.log("UID dell'utente trovato:", userUid);
                    // Ricerca dell'utente con quell'UID
                    const userRef = ref(db, `users/${userUid}`);
                    const userSnapshot = await get(userRef);
                    const user = userSnapshot.val();
                    if (user) {
                        // Ricerca di "favouriteCard"
                        const favouriteCardName = user.mycards.favouriteCard;
                        if (favouriteCardName) {
                            const cardData = user.mycards[favouriteCardName];
                            if (cardData) {
                                setUserData(cardData);
                                finalLayout = cardData.layout ? cardData.layout : layout;
                            } else {
                                console.log("Card preferita non trovata.");
                            }
                        } else {
                            console.log("L'utente non ha una card preferita.");
                        }
                    } else {
                        console.log("Utente non trovato.");
                    }
                } else {
                    console.log("Nickname non trovato.");
                }
            } else {
                if (user) {
                        const db = getDatabase();
                        let snapshot;
                        if (cardName) {
                            snapshot = await get(ref(db, `users/${user.uid}/mycards/${cardName}`));
                        } else {
                            snapshot = await get(ref(db, `users/${user.uid}/UserDatas`));
                        }
                        const data = snapshot.val();
                        if (data) {
                            setUserData(data);
                            finalLayout = cardName ? data.layout : layout;
                        } else {
                            navigate('/settings');
                        }
                }
            }
            setSelectedLayout(finalLayout);
        };
        fetchUserData();
    }, [user, navigate, cardName]);
    

    let SelectedComponent;
    switch(selectedLayout) {
        case 'layout1':
            SelectedComponent = DynamicCardA;
            break;
        case 'layout2':
            SelectedComponent = DynamicCardB;
            break;
        default:
            SelectedComponent = DynamicCardA;
    }

    return SelectedComponent && userData ? <SelectedComponent userData={userData} existCardName={cardName ? cardName : ''} layout={selectedLayout ? selectedLayout : layout} /> : <div>Caricamento...</div>;
};
export default CardComponentSelector
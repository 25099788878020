import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const Success = ({ componentName, htmlContent }) => {
    const firebaseFunctions = getFunctions(app);
    const navigate = useNavigate();
    const location = useLocation();
    const [user] = useAuthState(auth);
    const [functionsInstance, setFunctionsInstance] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);




    useEffect(() => {
      const initializeFirebaseFunctions = async () => {
          const functions = getFunctions(app);
          setFunctionsInstance(functions);
      };

      initializeFirebaseFunctions();
  }, []);

  
  useEffect(() => {
    if (functionsInstance) {
        // Funzione per inviare l'email
        const sendEmail = async () => {
            setLoading(true);
            try {
                const user = auth.currentUser;
                const userEmail = user.email; // Ottieni l'email dell'utente attualmente loggato        
                const sendEmailCallable = httpsCallable(functionsInstance, 'sendEmail');
                
                await sendEmailCallable({
                    to: userEmail,
                    subject: 'Oggetto dell\'email',
                    text: 'Testo dell\'email',
                    html: '<p>HTML dell\'email</p>'
                });
                setSuccess(true);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        const searchParams = new URLSearchParams(location.search);
        const paymentToken = searchParams.get('paymentToken');

        if (paymentToken) {
            const verificaTokenPagamento = httpsCallable(firebaseFunctions, 'verificaTokenPagamento');
            verificaTokenPagamento({ paymentToken })
                .then((result) => {
                    if (result.data.isValid) {
                        sendEmail(); 
                        navigate('/mycards');
                    } else {
                        navigate('/');
                    }
                })
                .catch((error) => {
                    console.error('Errore durante la verifica del token:', error);
                    navigate('/');
                });
        } else {
            navigate('/');
        }
    }
}, [functionsInstance, navigate, location.search]);

    return (
        <div>
            <p>Creating component...</p>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {success && <p>Email sent successfully!</p>}
        </div>
    );
};

export default Success;
